import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import Divider from '@material-ui/core/Divider';
import Footer from '../../Components/Layout/Footer';
import Routes from '../../Routing/Routes';
import Seo from '../../Routing/Seo';

import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';

type Props = {
  classes: Object,
};

const styles = () => ({
  layout: {
    ...LayoutStyles.centered800Layout,
    marginTop: '100px',
  },
});

function Privacy({ classes }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        {Seo.title(`Vie privée${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`)}
        {Seo.description(
          "Nous proposons un service d'aide à la personne respectueux de votre droit à la vie privée",
        )}
        {Seo.canonical(null, Routes.privacyUrl())}
      </Helmet>
      <Grid container className={classes.container}>
        <div className={classes.layout}>
          <Typography
            variant="h1"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: t('WELLO.PRIVACY.TITLE'),
            }}
          />

          <Divider light style={{ margin: '20px', background: 'none' }} />

          <Typography
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: t('WELLO.CGU.CONTENT_6'),
            }}
          />
          <Grid item md={12} style={{ padding: '60px' }} align="middle">
            <Button component="a" href="/">
              <Typography
                variant="subtitle2"
                color="textPrimary"
                component="p"
                dangerouslySetInnerHTML={{
                  __html: t('BACK'),
                }}
              />
            </Button>
          </Grid>
        </div>
      </Grid>
      <Footer />
    </>
  );
}

export default withStyles(styles)(Privacy);
